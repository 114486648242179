<template>
  <b-container>
    <b-row class="my-5">
      <b-col sm="3 offset-1" class="left-col">
        <div id="selection-text" align="left">
          <p>Select one or more students for a check-in.</p>
        </div>
        <div id="search-box">
        <b-form-group
          label="Filter"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="left"
          label-size="sm"
          class="mb-0"
         
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <!--<b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>-->
          </b-input-group>
        </b-form-group>
        </div>
 

 

        <div >
          <b-form-group
            label="Selection mode:"
            label-for="table-select-mode-select"
            label-cols-md="4"
            label-size="sm"
          >
            <b-form-select
              id="table-select-mode-select"
              v-model="selectMode"
              :options="modes"
              class="mb-3"
            ></b-form-select>
          </b-form-group>

          <p>
            <b-button size="sm" @click="selectAllRows">Select all</b-button>
            <b-button size="sm" @click="clearSelected">Clear selected</b-button>
            <!--<b-button size="sm" @click="testFn">Select 3rd row</b-button>
            <b-button size="sm" @click="unselectThirdRow">Unselect 3rd row</b-button>-->
          </p>

        <div>
          <b-table
            sticky-header="400px"
            :items="this.caseload"
            :fields="fields"
            :filter="filter"
            :select-mode="selectMode"
            sort-by='student_name'

            
            responsive="sm"
            ref="selectableTable"
            selectable
            @row-selected="onRowSelected"
            
            @filtered="onFiltered"   
          >
            <!-- Example scoped slot for select state illustrative purposes -->
            <template #cell(selected)="{ rowSelected }" \>
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
          </b-table>
          <!--<p>
            Selected Rows:<br>
            {{ selected }}
          </p>-->
          </div>
        </div>

      </b-col>


      <b-col sm="7">

        
          <!--<div>
            <p>{{ this.last }}</p>
          </div>-->


        <b-form @submit="submit" @reset="onReset" v-if="show">

          <div>
            <p>{{ this.students }}</p>
          </div>

          <b-form >
          <b-row>
            <b-col sm="3" class="">
          <b-form-checkbox-group
              v-model="form.isImpromptu"
              id="checkboxes-4"    
            >
            <div id="impromptu">
          <b-form-checkbox value="impromptu" size="sm">Impromptu</b-form-checkbox>
            </div>
          
              <!--<b-form-checkbox value="that">Check that out</b-form-checkbox>-->
          </b-form-checkbox-group>
          </b-col>

          <b-col sm="9">
          <b-form-group>
            <!--<label for="inputEmail3" class="col-sm-3 col-form-label">Select Date:</label>-->


          
            <b-form-datepicker v-model="form.timestamp">
            </b-form-datepicker>
          </b-form-group>
          </b-col>
          </b-row>


        </b-form>

          <hr/>
            <!--<div>
            <b-form-group 
            label="Form-checkbox-group inline checkboxes"

            >
            
              <b-form-checkbox-group class="col-sm-9"
                v-model="ydf_selected"
                :options="youthDevelopment"
                :aria-describedby="ariaDescribedby"
                name="flavour-1a"
                size="sm"
                inline
              ></b-form-checkbox-group>
            </b-form-group>
            </div>-->


          <b-form-group id="input-group-type">
            <label for="input-group-type" class="col-sm-3 col-form-label" label-cols-sm="2">Check-In Type:</label>
            <b-form-select class="col-sm-9" 
              
              id="checkin-type"
              v-model="form.checkinType"
              :options="checkinType"
              required
            ></b-form-select>
          </b-form-group>

          <b-form-group id="input-group-ydf">
            <label for="input-group-ydf" class="col-sm-3 col-form-label">YDF:</label>
            <b-form-select class="col-sm-9"
              id="youth-development"
              v-model="form.youthDevelopment"
              :options="youthDevelopment"
              required
            ></b-form-select>
          </b-form-group>

          <b-form-group id="input-group-length">
            <label for="input-group-length" class="col-sm-3 col-form-label">Check-In Length:</label>
            <b-form-select class="col-sm-9"
              id="checkin-length"
              v-model="form.checkinLength"
              :options="checkinLength"
              required
            ></b-form-select>
          </b-form-group>


          <hr/>

          <b-form-group id="input-group-5" >
              <b-form-textarea
                id="notes"
                v-model="form.notes"
                placeholder="Enter check-in notes..."
                rows="3"
                max-rows="8"
              ></b-form-textarea>
          </b-form-group>

          <b-button type="submit" variant="primary">Submit</b-button>
          <b-button type="reset" variant="danger">Reset</b-button>
        </b-form>


        <b-card class="mt-3"
          align="left"
          border-variant="dark" 
          bg-variant="dark" 
          
          text-variant="white"
        >
          <b-card-sub-title><b>Last Check-in:</b> </b-card-sub-title>
          <b-card-text>
            <b>Student:</b> {{ this.last.student_name }}
          </b-card-text>
          <b-card-text>
            <b>Date/time:</b> {{ this.last.last_check_in_date }}
          </b-card-text>
          <b-card-text>
            <b>Type/duration:</b> {{ this.last.last_check_in_type }}, {{ this.last.last_check_in_length }}
          </b-card-text>
          <b-card-text>
            <b>Note:</b> {{ this.last.last_check_in_notes }}
          </b-card-text>
        </b-card>

        <!--<b-card class="mt-3" header="Form Data Result">
          <pre class="m-0">{{ form }}</pre>
        </b-card>-->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import * as fb from '../firebase'
import firebase from 'firebase/app';

  export default {
    data() {
      return {
        copiedForm: {},
        form: {
          displaySelected: [],
          isImpromptu: [],
          checkinType: null,
          checkinLength: null,
          youthDevelopment: null,
          checkinDate: new Date().toLocaleString(),
          timestamp: new Date().toISOString(),
          notes: '',
          selected: []
        },
        youthDevelopment:[{ text: 'Select One', value: null },
          { text: 'Positive Relationships', value: 'Positive Relationships' },
          { text: 'Self-Advocacy', value: 'Self-Advocacy' },
          { text: 'Self-Regulation', value: 'Self-Regulation' },
          { text: 'Self-Motivation', value: 'Self-Motivation' },
          { text: 'N/A', value: 'N/A' }
          ],
        checkinType: [{ text: 'Select One', value: null }, 
        '1/1 Student Meeting',
        'Student Direct Message',
        'Google Classroom/Talking Points',
        'Parent Meeting',
        'Parent Communication',
        'Home Visit',
        'Letter Home',
        'School Staff Communication',
        'PPT/504 Meeting',
        'Classroom Observation/Push-In',
        'Group/Workshop/Lunch Bunch'],

        checkinLength: [{ text: 'Select One', value: null }, 
        '0-5 mins.',
        '5-10 mins.',
        '10-15 mins.',
        '15-20 mins.',
        '20-30 mins.',
        '30-45 mins.',
        '45-60 mins',
        '60+ mins.',
        'attempted'],
        show: true,

        // text/keyword filter
        filter: null,

        modes: ['multi', 'single', 'range'],
        //fields: ['selected', 'isActive', 'age', 'first_name', 'last_name'],
        fields: ["student_name"],
       // items: [this.caseload],
        /*items: [
          { isActive: true, age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
          { isActive: false, age: 21, first_name: 'Larsen', last_name: 'Shaw' },
          { isActive: false, age: 89, first_name: 'Geneva', last_name: 'Wilson' },
          { isActive: true, age: 38, first_name: 'Jami', last_name: 'Carney' }
        ],*/
        selectMode: 'multi',
        selected: [],
        students: "Select one or more students"

      }
      
    },

    watch: {


    },

    computed: {

      caseload(){
          return this.$store.getters.caseload;
      },

      last(){
        //alert('Computed ' + this.$store.getters.last)
        let i = this.$store.getters.last.map(a => a.last_check_in);

        var sorted = i.sort(function(a, b) {
            var c = new Date(a.last_timestamp).getTime();
            var d = new Date(b.last_timestamp).getTime();
            return d-c;
        });

        console.log(sorted)

        return sorted[0]

      },

      sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => {
          return { text: f.label, value: f.key }
        })
      }

    },
    
    methods: {

      onSubmit(event) {
        event.preventDefault()
        alert(JSON.stringify(this.form))
      },
      onReset(event) {
        event.preventDefault()
        // Reset our form values
        this.form.isImpromptu = []
        this.form.checkinType = null
        this.form.checkinLength = null
        this.form.youthDevelopment = null
        this.form.students = ""
        this.form.checkinDate = new Date().toLocaleDateString()
        this.form.timestamp = new Date().toISOString()
        this.form.dateOccurred = new Date().toISOString()
        this.form.notes = ''
        this.students = ''
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
        this.clearSelected()
      },

      reset() {
        //event.preventDefault()
        // Reset our form values
        this.form.isImpromptu = []
        this.form.checkinType = null
        this.form.checkinLength = null
        this.form.youthDevelopment = null
        this.form.students = ""
        this.form.checkinDate = new Date().toLocaleDateString()
        this.form.timestamp = new Date().toISOString()
        this.form.dateOccurred = new Date().toISOString()
        this.form.notes = ''
        this.students = ''
        // Trick to reset/clear native browser form validation state
        this.show = false
        this.$nextTick(() => {
          this.show = true
        })
        this.clearSelected()
      },
      onRowSelected(items) {
        this.selected = items
        this.form.selected = items
        this.students = 'Select one or more students'
        
        const displayList = [];
        items.forEach(element => {
          //console.log(element.student_name);
          
          displayList.push(element.student_name);
          var final = displayList.join(', ')
          console.log(final)
          //this.form.selected = final
          this.students = final

        //if (this.form.check_in_type === '1/1 Student Meeting') {}
          

        });
      },
      selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
      },
      clearSelected() {
        this.$refs.selectableTable.clearSelected()
        this.form.selected = []
        this.students = ""
      },
      selectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.selectRow(2)
      },
      unselectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.unselectRow(2)
      },

      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },



      testFn () {
        console.log(this.getLast())
      },

      submit(event) {
        event.preventDefault()

        //var y = this.form.selected

        //const copiedForm = Object.assign({}, this.form);
        this.copiedForm = JSON.parse(JSON.stringify(this.form));

        this.copiedForm.selected.forEach(element => {
          //alert(element.student_name)
          console.log(element.document_id);

          var check_in_id = element.student_name.replace(', ', '').toLowerCase() + '_' + String(this.form.timestamp)

          // build tracker_key for use in the monthly trackers, reference to it for the primary check_ins array
          let month = this.currentMonth().toLowerCase()
          let type = this.copiedForm.checkinType.replace(/\//g, "_").toLowerCase()
          let type2 = type.replace(' ', '_')
          let typeFinal = type2.split('_', 2)[0] + '_' +type2.split('_', 2)[1];
          let typeFinal2 = typeFinal.split(' ')[0]
          var tracker_key = "track_" + month + "_" + typeFinal2          

          this.primaryCheckIn(element, check_in_id, tracker_key)
          this.lastOneToOne(element)
          this.universalTracker(element, check_in_id, tracker_key)
          },
        this.reset()

        )

      },

      universalTracker(element, check_in_id, tracker_key) {
        
        fb.otcStudents.doc(element.district.toLowerCase()).collection('prod_otcs').doc(element.otc).collection('prod_caseload').doc(element.document_id).update({
            [`${tracker_key}`]: firebase.firestore.FieldValue.arrayUnion({
              "check_in_id": check_in_id,
              "check_in_type": this.copiedForm.checkinType,
          }) 
        })

      },


      lastOneToOne(element) {
        if (this.copiedForm.checkinType == '1/1 Student Meeting') {
           var last_one_to_one = new Date(this.copiedForm.timestamp).toLocaleDateString('en-US', {timeZone: 'UTC'}) + ' ' + new Date(this.copiedForm.timestamp).toLocaleTimeString()

          fb.otcStudents.doc(element.district.toLowerCase()).collection('prod_otcs').doc(element.otc).collection('prod_caseload').doc(element.document_id).update({
            last_one_to_one: last_one_to_one //this.copiedForm.timestamp,
          })
        } 
      },

      

      primaryCheckIn(element, check_in_id, tracker_key) {

          let district = element.district.toLowerCase()
          let otc = element.otc
        
        /*if (this.form.checkinType[0] == '1/1 Student Meeting') {
          var last_one_to_one = new Date().toLocaleString() //this.form.checkinDate
        }*/

        
        fb.otcStudents.doc(district).collection('prod_otcs').doc(otc).collection('prod_caseload').doc(element.document_id).update({

            last: {
              student_name: element.student_name,
              last_check_in_type: this.copiedForm.checkinType,
              last_check_in_length: this.copiedForm.checkinLength,
              last_check_in_date: new Date().toLocaleString(), //this.form.checkinDate,
              last_timestamp: new Date().toISOString(),
              last_check_in_id: check_in_id,
              last_check_in_notes: this.copiedForm.notes,
            },

            check_ins: firebase.firestore.FieldValue.arrayUnion({
                    "check_in_id": check_in_id,
                    "is_impromptu": this.copiedForm.isImpromptu,
                    "student_name": element.student_name,
                    "student_id": element.student_id,
                    "check_in_type": this.copiedForm.checkinType,
                    "check_in_length": this.copiedForm.checkinLength,
                    "youth_development": this.copiedForm.youthDevelopment,
                    "check_in_date": new Date().toLocaleString(), //this.form.checkinDate,
                    "timestamp": this.copiedForm.timestamp,
                    "notes": this.copiedForm.notes,
                    "tracker_key_ref": tracker_key,
                    "district": element.district,
                    "otc": element.otc

            })

          })

      },

      currentMonth() {
        var month = new Array();
        month[0] = "January";
        month[1] = "February";
        month[2] = "March";
        month[3] = "April";
        month[4] = "May";
        month[5] = "June";
        month[6] = "July";
        month[7] = "August";
        month[8] = "September";
        month[9] = "October";
        month[10] = "November";
        month[11] = "December";

        var d = new Date();
        var n = month[d.getMonth()];
        return n
      },
    
    }

    
  }
</script>


<style>
#selection-text {
  font-size: 18px;
  font-weight: bold;
}
#search-box {
  margin-top: 0px !important;
  margin-bottom: 20px;
  align-content: initial;
}

#impromptu {
  margin-top: 5px;
}

.left-col {
  border-right: 1px dashed #333;
}
.b-form-datepicker {
  margin-right:4px;
}

.table {
  color: black !important;
}

label {
  text-align:left;
  font-weight: bold;
}

.col-form-label {
  text-align:left
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>