import Vue from 'vue'
import VueRouter from 'vue-router'
//import Home from '../views/Home.vue'
import Summary from '../views/Summary.vue'
import Tracker from '../views/altTracker.vue'
import Login from '../views/Login.vue'
import NewCheckin from '../views/NewCheckin.vue'
import CheckinHistory from '../views/CheckinHistory.vue'
import Alerts from '../views/Alerts.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
    meta: { hideNavigation: true }
  },
  /*{
    path: '/home',
    name: 'Home',
    component: Home
  },*/
  {
    path: '/tracker',
    name: 'Caseload Tracker',
    
  
    component: Tracker
  },

  {
    path: '/summary',
    name: 'Summary',
    
  
    component: Summary
  },


  {
    path: '/check-in',
    name: 'New Checkin',
    
  
    component: NewCheckin
  },

  {
    path: '/check-in-history',
    name: 'Checkin History',
    
  
    component: CheckinHistory
  },

  {
    path: '/last-check-in',
    name: 'Last Check-in',
    
  
    component: Alerts
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
