<template>
  <div id="app">
    <div id="nav" v-if="!$route.meta.hideNavigation">
      <b-navbar toggleable="lg" variant="light" fixed='top' class="border-bottom ">
        <b-navbar-brand>

          <b-img src="./assets/rise_logo_vertical.png" height="40"></b-img>

        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item><router-link to="/tracker">OTC Tracker</router-link></b-nav-item>
            <b-nav-item><router-link to="/check-in-history">Check-in History</router-link></b-nav-item>
            <b-nav-item><router-link to="/summary">Summary</router-link></b-nav-item>
            <b-nav-item><router-link to="/last-check-in">Last Check-in</router-link></b-nav-item>
          
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
        <b-nav-item right href="#" @click="googleSignOut"><router-link to="/"> Logout </router-link></b-nav-item>

        
 
      </b-navbar-nav>
        </b-collapse>
      </b-navbar>
  </div>
    <router-view/>
  </div>
</template>


<script>
import firebase from "firebase";
// These imports load individual services into the firebase namespace.
import 'firebase/auth';


export default {
  name: "Login",
  methods: {
  
    googleSignOut: function() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          alert("Successfully signed out.");

          this.$store.replaceState({})
          this.$router.push({ path: '/' });
        });
    },
    
    googleCheckAuth: function() {
      let user_test = firebase
        .auth()
        .currentUser
        .displayName
      
      console.log(user_test)
    },

  },
};
</script>


<style>

@font-face {
    font-family: "My Custom Font";
    src: url("./assets/lato/Lato-Regular.ttf") format("truetype");
}
#app {
  font-family: "My Custom Font",Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
}

#nav {
  padding: 40px;
  border-color: black;
}

#nav a {
  font-weight: bold;
  color: #002D86;
}



#nav a.router-link-exact-active {
  color: white;
  background-color: #002D86;
  padding: 10px;
  border-radius: 4px;
  /* background */
}


</style>
