<template>
<b-container >
 
    
    <b-row align-h="center">
      <b-col/>
      <b-col cols=10 >
   <b-card no-body class="overflow-hidden login" >
 <b-row no-gutters align-v="center">
      <b-col>
        <b-img src="@/assets/RISE_BluePattern_darkblueLg.png" fluid-grow alt="Responsive image" class="blimage"></b-img>
        <p class="text-break">Welcome to the OTC Web App!</p>
      </b-col>
      <b-col>
        <b-card-body>

           <b-img src="@/assets/rise_logo_vertical.png" fluid center width="200px" height="200px" alt="Responsive image"></b-img>

          <div>
            <b-button
            @click="googleSignIn"
            variant="light"
            size="sm"
            >
            <b-img src="@/assets/login/btn_google_light_normal_ios.svg"></b-img>

              Sign in with Google
            </b-button>
            
          </div>
         
        </b-card-body>
      </b-col>
    </b-row>
  </b-card>
      </b-col>
  <b-col/>
    </b-row>
  
</b-container>
</template>

<script>
import firebase from "firebase";
// These imports load individual services into the firebase namespace.
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';


export default {
  name: "Login",
  methods: {
    googleSignIn: function() {
      let provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
        .then(() =>
        firebase
        .auth()
        .signInWithPopup(provider)
        .then((result) => {
          let token = result.credential.accessToken;
          let user = result.user;
            console.log(token); // Token
            console.log(user); // User that was authenticated
            
          this.$router.push({ path: 'tracker' });
          this.$router.go({ path: 'tracker' });
        })
        )
        .catch((err) => {
          console.log(err); // This will give you all the information needed to further debug any errors
        })
        ;
    },
  
    googleSignOut: function() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          alert("Successfully signed out.");
          this.$router.go({ path: 'tracker' });
          this.$router.push({ path: 'about' });
        });
    },

    googleCheckAuth: function() {
      let user_test = firebase
        .auth()
        .currentUser
        .displayName
      
      console.log(user_test)
    },

  },
};
</script>


<style scoped>

@font-face {
    font-family: "My Custom Font";
    src: url("../assets/lato/Lato-Regular.ttf") format("truetype");
}


   body{
    background: white/* #e9ecef*/;
   }

html {background-color: black}

.blimage{
  object-fit: cover;
  height: 100%;
}

  p{
    text-align: left;
    color: white;
    position: absolute;
  top: 35%;
  left: 10%;
  font-size: 1.5rem;
  font-family: "My Custom Font", Verdana, Tahoma;
  }
   /* .row-test{
     background: blue;
     background-image: url(../assets/RISE_BluePattern_darkblueLg.png);
     padding-top: 180px;
     padding-bottom: 180px;
   } */

 
    .login{
     border-radius:20px; 
    top: 35%;
     vertical-align: middle;
     border-color: rgba(0, 0, 0, 0.5);
     box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.5);
     width: 600px;
     margin: 0 auto; 

     
    }
   #horizontal-container{
     margin-top: 100px;
   }

   .cont-back {
     padding: 10%;
     padding-top: 15%;
     padding-bottom: 70%;

    width: 100%;
  /* background-image: url("../assets/RISE_BluePattern_darkblueLg_light.png"); */
  
}
</style>