import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import "./style/custom.scss"

import './plugins/bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store'

import firebase from "firebase/app"
import 'firebase/auth';

Vue.config.productionTip = false

/*
store.dispatch("setStudentData")
  .then(



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


  )*/


new Vue({
  router,
  store,
  created(){
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/firebase.User
        // console.log(user)
        // this.$store.commit("setUser",user.uid)
        // console.log(this.$store.state.user_uid)
        this.$store.dispatch("getUserSchools",user.uid)
        //this.$store.dispatch("uid",user.uid)
        // ...
      } else {
        // User is signed out
        console.log('not logged in')
        // ...
      }
    })


  },
  render: h => h(App)
}).$mount('#app')

  