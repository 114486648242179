<template>
  <b-container>
    <b-row class="my-5">


      <b-col sm="8 offset-2">

        <div id="profile-card">
            <!--<p>{{ this.students }}</p>-->
          

        <b-card class="mt-3"
          align="left"
          border-variant="dark" 
          bg-variant="dark" 
          text-variant="white"
          v-for="(i, key) in this.students" :key="key.id"
          >
            <b-card-text>
              <b>Student:</b> {{ i.student_name || '' }}
            </b-card-text>
            <b-card-text>
              <b>Status:</b> {{ i.on_track_status || ''  }}
            </b-card-text>

          </b-card>
        </div>

  <b-container fluid>
    <!-- User Interface controls -->
    <b-row>
      <!--<b-col lg="6" class="my-1">
        <b-form-group
          label="Sort"
          label-for="sort-by-select"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
          v-slot="{ ariaDescribedby }"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sort-by-select"
              v-model="sortBy"
              :options="sortOptions"
              :aria-describedby="ariaDescribedby"
              class="w-75"
            >
              <template #first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>

            <b-form-select
              v-model="sortDesc"
              :disabled="!sortBy"
              :aria-describedby="ariaDescribedby"
              size="sm"
              class="w-25"
            >
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>-->

      <!--<b-col lg="6" class="my-1">
        <b-form-group
          label="Initial sort"
          label-for="initial-sort-select"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="initial-sort-select"
            v-model="sortDirection"
            :options="['asc', 'desc', 'last']"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>-->

      <b-col md="6" class="my-1">
        <b-form-group
          label="Filter by OTC"
          label-for="filter-input"
          label-cols-sm="3"
          label-align-sm="left"
          label-size="sm"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filter-input"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            ></b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>


      <!--<b-col sm="5" md="6" class="my-1">
        <b-form-group
          label="Per page"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          label-size="sm"
          class="mb-0"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            size="sm"
          ></b-form-select>
        </b-form-group>
      </b-col>-->

      <!--<b-col sm="7" md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="fill"
          size="sm"
          class="my-0"
        ></b-pagination>
      </b-col>-->
    </b-row>

    <!-- Main table element -->
    <b-table
      :items="this.caseloadSummary"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :filter-included-fields="filterOn"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
    thead-class="green-bg bg-primary text-white"
      small
      @filtered="onFiltered"
    >
      <template #cell(name)="row">
        {{ row.value.first }} {{ row.value.last }}
      </template>

      <template #cell(actions)="row">
        <b-button size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1 action">
          Edit
        </b-button>
        <b-button class="mr-1 action" size="sm" @click="openDeleteModal(row.item, row.index, $event.target)">
          Delete
        </b-button>
      </template>

      <template #row-details="row">
        <b-card v-for="(i, key) in row.item" :key="key.id">
          <ul >
            <li v-for="(value, key) in i" :key="key">{{ key }}: {{ value }}</li>
            <!--<li v-for="note in row.item.all_notes" :key="note.notes">{{ key }}: {{ value }}</li>-->
          </ul>
        </b-card>
      </template>
      <!--<template v-slot:cell()="{ value, item, field: { key }}">
          <template v-if="!item.isEditing">{{ value }}</template>
        <b-form-input v-else v-model="item[key]" />
      </template>
      
      <template v-slot:cell(actions)="{ item }">
        <b-dropdown variant="primary" text="Actions">
          <b-dropdown-item @click="onEdit(item)">{{ item.isEditing ? 'Save' : 'Edit' }}</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item>Delete</b-dropdown-item>
        </b-dropdown>
      </template>-->
    </b-table>

  </b-container>


  
      <!--<b-card class="mt-3" header="Form Data Result">
        <pre class="m-0">{{ form }}</pre>
      </b-card>-->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
//import * as fb from '../firebase'
//import firebase from 'firebase/app';

  export default {
    data() {
      return {
        fields: [
          //{key: 'check_in_date', label: 'Date', sortable: true, sortDirection: 'desc', sort_priority: 0},
          {key: 'otc', label: 'OTC', sortable: true, sortDirection: 'desc', sort_priority: 0},
          //{key: 'student_name', label: 'Name', sortable: false, sortDirection: 'desc', sort_priority: 0},
          {key: 'on_track_status', label: 'Status', sortable: true, sort_priority: 0},
          {key: 'count', label: 'Count', sortable: true, sort_priority: 0},
          //{key: 'notes', label: 'Notes', thStyle: { width: '300px'}},
          /*{
            key: 'isActive',
            label: 'Is Active',
            //formatter: (value, key, item) => {
            //  return value ? 'Yes' : 'No'
            //},
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true
          },*/
          //{ key: 'actions', label: 'Actions' }
        ],
        old_fields: [
          { key: 'name', label: 'Person full name', sortable: true, sortDirection: 'desc' },
          { key: 'age', label: 'Person age', sortable: true, class: 'text-center' },
          {
            key: 'isActive',
            label: 'Is Active',
            //formatter: (value, key, item) => {
            //  return value ? 'Yes' : 'No'
            //},
            sortable: true,
            sortByFormatted: true,
            filterByFormatted: true
          },
          { key: 'actions', label: 'Actions' }
        ],
        
        totalRows: 1,
        currentPage: 1,
        perPage: "Show a lot",
        pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
        sortBy: 'Date',
        sortDesc: false,
        sortDirection: 'desc',
        side_filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: ''

        },
        deleteModal: {
          id: 'delete-modal',
          title: '',
          content: ''

        },
        copiedModalContent: {},

        v:[],







        show: true,

        // text/keyword filter
        filter: '',

        modes: [
          //'multi', 
          'single', 
          //'range'
        ],
        //fields: ['selected', 'isActive', 'age', 'first_name', 'last_name'],
        caseload_fields: ["student_name"],
       // items: [this.caseload],
        /*items: [
          { isActive: true, age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
          { isActive: false, age: 21, first_name: 'Larsen', last_name: 'Shaw' },
          { isActive: false, age: 89, first_name: 'Geneva', last_name: 'Wilson' },
          { isActive: true, age: 38, first_name: 'Jami', last_name: 'Carney' }
        ],*/
        selectMode: 'single',
        selected: [],
        students: []

      }
      
    },

    watch: {
      globalQuery() {
        // Force refreshing the data table.
        this.$root.$emit('bv::refresh::table', this.v)
      }
    },
    

    computed: {


        caseloadSummary() {
            var data = this.$store.getters.caseload_summary;
            //let students = this.$store.state.student_data
            //let data = students.map(c=> ({
            //                    otc: c.otc,
            //                    on_track_status: c.on_track_status
            //                            }))
            //var data = this.caseloadSummary
            console.log(data)
            //alert(data.length)

            var otc_list = new Set(data.map(d=>d.otc))
            //console.log(otcs)
            
            var status_list = new Set(data.map(d=>d.on_track_status))
            console.log(status_list)

            var otc_summary_array = []

            otc_list.forEach(element => {
                //console.log(element)
                let i = element
                
                status_list.forEach(element => {
                    //console.log(element)
                    
                    let j = element
                    let len = data.filter(d => d.on_track_status==j && d.otc==i).length;
                    let otc_status_count = {'otc': i, 'on_track_status': j, 'count': len}
                    otc_summary_array.push(otc_status_count)
                    
                });


            });
            console.log(otc_summary_array)
            return otc_summary_array


        },


      caseload(){
          return this.$store.getters.caseload;
      },


      check_in_history(){
          return this.$store.getters.check_in_history;
      },


      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => {
            return { text: f.label, value: f.key }
          })
      },

    },

    mounted() {
      // Set the initial number of items
      //this.totalRows = this.v.length // not using this anymore
    },
    
    methods: {



      selectAllRows() {
        this.$refs.selectableTable.selectAllRows()
      },
      clearSelected() {
        this.$refs.selectableTable.clearSelected()
        this.form.selected = []
        this.students = ""
      },
      selectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.testFn();
        //this.$refs.selectableTable.selectRow(2)
      },
      unselectThirdRow() {
        // Rows are indexed from 0, so the third row is index 2
        this.$refs.selectableTable.unselectRow(2);
      },

      onFilteredStudents(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },



      testFn () {
        console.log(typeof this.selected);
        alert(this.check_in_history);
      },

      checkins_source(doc_id) {
        //let student = doc_id;
        //console.log(student)
        let data = this.$store.state.student_data.filter(x=>x.document_id==doc_id)[0].check_ins
        let checkins = data.map(c=> ({student_name: c.student_name, 
                                      check_in_id: c.check_in_id, 
                                      check_in_type: c.check_in_type,
                                      check_in_length: c.check_in_length, 
                                      youth_development: c.youth_development,
                                      is_impromptu: c.is_impromptu,
                                      student_id: c.student_id,
                                      timestamp: c.timestamp,
                                      display_date: new Date(c.timestamp).toLocaleDateString('en-US', {timeZone: 'UTC'}) + ' ' + new Date(c.timestamp).toLocaleTimeString(),
                                      sort_timestamp: new Date(c.timestamp).toLocaleString('en-US', {timeZone: 'UTC'}),
                                      check_in_date: c.check_in_date, 
                                      notes: c.notes,
                                      document_id: doc_id,
                                      tracker_key_ref: c.tracker_key_ref,
                                      district: c.district,
                                      otc: c.otc
                                      }))


        //alert(typeof checkins)

        var sorted = checkins.sort(function(a, b) {
              var c = new Date(a.sort_timestamp).getTime();
              var d = new Date(b.sort_timestamp).getTime();
              return d-c;
          });

          return sorted


            //return checkins
        },

        onEdit(item) {
          if (item.isEditing)
            item.isEditing = false;
          else
            this.$set(item, 'isEditing', true)
            //alert(item)
        },

      onFiltered() {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = this.v.length
        this.currentPage = 1
      },

    },

}
</script>


<style>

#search-box {
  margin-top:100px;
  margin-bottom: 20px;
  align-content: initial;
}

.left-col {
  border-right: 1px dashed #333;
}

#profile-card {
  margin-top: -15px;
  margin-bottom: 15px;
}

.action {
  height: 29px !important;
  width: 55px !important;
  margin-top: 2px;
  padding-bottom: 2px !important;
}

#confirm-delete {
  margin-top: 0px;
}

.b-form-datepicker {
  margin-right:4px;
}

.table {
  color: black !important;
}

label {
  text-align:left;
  font-weight: bold;
}

.col-form-label {
  text-align:left
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>