
<template>
    <b-row>
        <b-table
        :items="this.grades_pivot"
        sort-by="period"
>

<!--- dynamic columns --->
<template v-for="mp in this.markingPeriods" v-slot:[`cell(${mp})`]="row" >
    <div :key="mp+'-column'">
        {{row.value.grade}}
    </div>
</template>
<!--- dynamic columns --->

</b-table>
    </b-row>
</template>

<script>
export default {
    props: {

       student_doc_id: String,
        
    },
    data(){
    return{
        //student_doc_id: ct_merryville_60e86e7cfc13ae524a000017
        markingPeriods: ['Q1','Q2','Q3','Q4','S1','S2','Y1']
    }
    },
    computed: {
    grades_pivot() {
           
        let student = this.student_doc_id;
        //get data for the selected student
        let data = this.$store.state.student_data.filter(x=>x.document_id==student)[0].courses

        //get all the marking periods included in the student's grades
        let columns = [...new Set(data.map(d=>d.storecode))]
        columns=columns.sort()

        //the database has one record per course per marking period. we need to
        //get a unique list of courses included in the student's grades in order
        //to make sure the final table has only one row per course
        let unqClasses = [...new Set(data.map(d=>d.course_name))]
        let uniqueData = []

        //though different marking periods of a course will have different grades
        //they have the same teacher and class period. this for loop adds that information
        //back into the uniqueData array
        for (let u in unqClasses) {
            let classData = data.filter(x=>x.course_name==unqClasses[u])[0]
            uniqueData.push(classData)
        }
        //set up the fixed columns
        let classes = uniqueData.map(c=> ({course_name: c.course_name, teacher: c.teacher, period: c.period}))
         
        //look through all the data for each class and get all the marking period grades. mash those grades
        //onto a single record for each class
        for (let c in classes) {
            for (let m in columns) {
            let grade = data.filter(x=> x['storecode']==columns[m] && x['course_name']==classes[c].course_name)[0]
            classes[c][columns[m]]=grade
            }
        }
            return classes
        },
 
        },
    methods: {
        say: function (msg) {
            console.log(msg)
            },
        
    },


}
</script>

<style>

</style>
