<template>
  <div>
    <b-tabs content-class="mt-3" justified>
      <b-tab title="View ALL Check-ins by OTC" active>
        <b-container>
          <b-row class="my-5">
            <b-col sm="2 offset-1" class="left-col">
              <div id="selection-text" align="left">
                <p>Select an OTC to view their check-in history.</p>
              </div>
              <!--<div id="search-box">
          <b-form-group
            label="Filter"
            label-for="filter-input"
            label-cols-sm="3"
            label-align-sm="left"
            label-size="sm"
            class="mb-0"
          
          >
          <b-input-group size="sm">
              <b-form-input
                id="filter-input"
                v-model="side_filter"
                type="search"
                placeholder="Search for a student or OTC"
              ></b-form-input>

              !--<b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
              </b-input-group-append>--
            </b-input-group>
          </b-form-group>
        </div>-->

              <div>
                <div>
                  <b-table
                    sticky-header="400px"
                    :items="this.otcMenu"
                    :fields="otcMenuFields"
                    :filter="side_filter"
                    :select-mode="selectMode"
                    sort-by="otc"
                    responsive="sm"
                    ref="selectableTable"
                    selectable
                    @row-selected="onOtcRowSelected"
                    @filtered="onFilteredStudents"
                  >
                    <!-- Example scoped slot for select state illustrative purposes -->
                    <template #cell(selected)="{ rowSelected }" \>
                      <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Selected</span>
                      </template>
                      <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not selected</span>
                      </template>
                    </template>
                  </b-table>
                  <!--<p>
                Selected Rows:<br>
                {{ selected }}
            </p>-->
                </div>
              </div>
            </b-col>

            <b-col sm="8" offset="0">
              <b-container fluid>
                <!-- User Interface controls -->
                <b-row>
                  <b-col md="12" class="my-1">
                    <b-form-group
                      label="Filter on Notes/Type"
                      label-for="filter-input"
                      label-cols-sm="3"
                      label-align-sm="left"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          placeholder="Type to Search"
                        ></b-form-input>

                        <b-input-group-append>
                          <b-button :disabled="!filter" @click="filter = ''"
                            >Clear</b-button
                          >
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col sm="5" md="5" class="my-1">
                    <b-form-group
                      label="Per page"
                      label-for="per-page-select"
                      label-cols-sm="6"
                      label-cols-md="4"
                      label-cols-lg="3"
                      label-align-sm="left"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>

                  <b-col sm="5" offset="" md="5" class="my-1">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalAllRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>

                  <b-col cols="1">
                    <download-csv
                      class="btn btn-default"
                      :data="this.allCheckins"
                      name="otc_checkin_tracker_export.csv"
                    >
                      <b-icon icon="cloud-download-fill"> </b-icon> Export
                    </download-csv>
                  </b-col>
                </b-row>

                <!-- Main table element -->
                <b-table
                  :items="this.allCheckins"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  stacked="md"
                  thead-class="green-bg bg-primary text-white"
                  small
                  @filtered="onOtcFiltered"
                >
                  <template #cell(name)="row">
                    {{ row.value.first }} {{ row.value.last }}
                  </template>

                  <template #cell(actions)="row">
                    <b-button
                      size="sm"
                      @click="info(row.item, row.index, $event.target)"
                      class="mr-1 action"
                    >
                      Edit
                    </b-button>
                    <b-button
                      class="mr-1 action"
                      size="sm"
                      @click="
                        openDeleteModal(row.item, row.index, $event.target)
                      "
                    >
                      Delete
                    </b-button>
                  </template>

                  <template #row-details="row">
                    <b-card v-for="(i, key) in row.item" :key="key.id">
                      <ul>
                        <li v-for="(value, key) in i" :key="key">
                          {{ key }}: {{ value }}
                        </li>
                        <!--<li v-for="note in row.item.all_notes" :key="note.notes">{{ key }}: {{ value }}</li>-->
                      </ul>
                    </b-card>
                  </template>
                </b-table>
              </b-container>
            </b-col>
          </b-row>
        </b-container>

        <!--############################################## Second Tab ##########################################################  -->
      </b-tab>
      <b-tab title="View Check-ins by OTC-Student">
        <b-container>
          <b-row class="my-5">
            <b-col sm="3 offset-1" class="left-col">
              <div id="selection-text" align="left">
                <p>Select a student to view their check-in history.</p>
              </div>
              <div id="search-box">
                <b-form-group
                  label="Filter"
                  label-for="filter-input"
                  label-cols-sm="3"
                  label-align-sm="left"
                  label-size="sm"
                  class="mb-0"
                >
                  <b-input-group size="sm">
                    <b-form-input
                      id="filter-input"
                      v-model="side_filter"
                      type="search"
                      placeholder="Search for a student or OTC"
                    ></b-form-input>

                    <!--<b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
            </b-input-group-append>-->
                  </b-input-group>
                </b-form-group>
              </div>

              <div>
                <div>
                  <b-table
                    sticky-header="400px"
                    :items="this.check_in_history"
                    :fields="caseload_fields"
                    :filter="side_filter"
                    :select-mode="selectMode"
                    sort-by="otc_student"
                    responsive="sm"
                    ref="selectableTable"
                    selectable
                    @row-selected="onRowSelected"
                    @filtered="onFilteredStudents"
                  >
                    <!-- Example scoped slot for select state illustrative purposes -->
                    <template #cell(selected)="{ rowSelected }" \>
                      <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Selected</span>
                      </template>
                      <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not selected</span>
                      </template>
                    </template>
                  </b-table>
                  <!--<p>
                Selected Rows:<br>
                {{ selected }}
            </p>-->
                </div>
              </div>
            </b-col>

            <b-col sm="7">
              <div id="profile-card">
                <!--<p>{{ this.students }}</p>-->

                <b-card
                  class="mt-3"
                  align="left"
                  border-variant="dark"
                  bg-variant="dark"
                  text-variant="white"
                  v-for="(i, key) in this.students"
                  :key="key.id"
                >
                  <b-card-text>
                    <b>Student:</b> {{ i.student_name || "" }}
                  </b-card-text>
                  <b-card-text>
                    <b>Status:</b> {{ i.on_track_status || "" }}
                  </b-card-text>
                </b-card>
              </div>

              <b-container fluid>
                <!-- User Interface controls -->
                <b-row>
                  <b-col md="12" class="my-1">
                    <b-form-group
                      label="Filter on Notes/Type"
                      label-for="filter-input"
                      label-cols-sm="3"
                      label-align-sm="left"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-input-group size="sm">
                        <b-form-input
                          id="filter-input"
                          v-model="filter"
                          type="search"
                          placeholder="Type to Search"
                        ></b-form-input>

                        <b-input-group-append>
                          <b-button :disabled="!filter" @click="filter = ''"
                            >Clear</b-button
                          >
                        </b-input-group-append>
                      </b-input-group>
                    </b-form-group>
                  </b-col>

                  <b-col sm="5" md="6" class="my-1">
                    <b-form-group
                      label="Per page"
                      label-for="per-page-select"
                      label-cols-sm="6"
                      label-cols-md="4"
                      label-cols-lg="3"
                      label-align-sm="left"
                      label-size="sm"
                      class="mb-0"
                    >
                      <b-form-select
                        id="per-page-select"
                        v-model="perPage"
                        :options="pageOptions"
                        size="sm"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>

                  <b-col sm="7" md="6" class="my-1">
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :per-page="perPage"
                      align="fill"
                      size="sm"
                      class="my-0"
                    ></b-pagination>
                  </b-col>
                </b-row>

                <!-- Main table element -->
                <b-table
                  :items="this.v"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :sort-direction="sortDirection"
                  stacked="md"
                  thead-class="green-bg bg-primary text-white"
                  small
                  @filtered="onFiltered"
                >
                  <template #cell(name)="row">
                    {{ row.value.first }} {{ row.value.last }}
                  </template>

                  <template #cell(actions)="row">
                    <b-button
                      size="sm"
                      @click="info(row.item, row.index, $event.target)"
                      class="mr-1 action"
                    >
                      Edit
                    </b-button>
                    <b-button
                      class="mr-1 action"
                      size="sm"
                      @click="
                        openDeleteModal(row.item, row.index, $event.target)
                      "
                    >
                      Delete
                    </b-button>
                  </template>

                  <template #row-details="row">
                    <b-card v-for="(i, key) in row.item" :key="key.id">
                      <ul>
                        <li v-for="(value, key) in i" :key="key">
                          {{ key }}: {{ value }}
                        </li>
                        <!--<li v-for="note in row.item.all_notes" :key="note.notes">{{ key }}: {{ value }}</li>-->
                      </ul>
                    </b-card>
                  </template>
                  <!--<template v-slot:cell()="{ value, item, field: { key }}">
          <template v-if="!item.isEditing">{{ value }}</template>
        <b-form-input v-else v-model="item[key]" />
      </template>
      
      <template v-slot:cell(actions)="{ item }">
        <b-dropdown variant="primary" text="Actions">
          <b-dropdown-item @click="onEdit(item)">{{ item.isEditing ? 'Save' : 'Edit' }}</b-dropdown-item>
          <b-dropdown-divider></b-dropdown-divider>
          <b-dropdown-item>Delete</b-dropdown-item>
        </b-dropdown>
      </template>-->
                </b-table>
              </b-container>

              <!--<b-card class="mt-3" header="Form Data Result">
        <pre class="m-0">{{ form }}</pre>
      </b-card>-->
            </b-col>
          </b-row>
        </b-container>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
//import * as fb from '../firebase'
//import firebase from 'firebase/app';
import JsonCSV from "vue-json-csv";

export default {
  components: {
    "download-csv": JsonCSV,
  },
  data() {
    return {
      fields: [
        //{key: 'check_in_date', label: 'Date', sortable: true, sortDirection: 'desc', sort_priority: 0},
        {
          key: "otc",
          label: "OTC",
          sortable: true,
          sortDirection: "desc",
          sort_priority: 0,
        },
        {
          key: "display_date",
          label: "Date",
          sortable: true,
          sortDirection: "desc",
          sort_priority: 0,
        },
        {
          key: "student_name",
          label: "Name",
          sortable: true,
          sortDirection: "desc",
          sort_priority: 0,
        },
        {
          key: "check_in_type",
          label: "Type",
          sortable: true,
          sort_priority: 0,
        },
        {
          key: "youth_development",
          label: "YDF",
          sortable: true,
          sort_priority: 0,
        },
        { key: "notes", label: "Notes", thStyle: { width: "300px" } },
      ],

      totalRows: 1,
      totalAllRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
      sortBy: "Date",
      sortDesc: false,
      sortDirection: "desc",
      side_filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      v: [],
      allCheckins: [],

      form: {
        isImpromptu: "",
        name: "",
        checkinType: null,
        checkinLength: null,
        students: "",
        date: "",
        notes: "",
        selected: [],
      },
      youthDevelopment: [
        { text: "Select One", value: null },
        { text: "Positive Relationships", value: "Positive Relationships" },
        { text: "Self-Advocacy", value: "Self-Advocacy" },
        { text: "Self-Regulation", value: "Self-Regulation" },
        { text: "Self-Motivation", value: "Self-Motivation" },
        { text: "N/A", value: "N/A" },
      ],
      checkinType: [
        { text: "Select One", value: null },
        "1/1 Student Meeting",
        "Student Direct Message",
        "Google Classroom/Talking Points",
        "Parent Meeting",
        "Parent Communication",
        "Home Visit",
        "Letter Home",
        "School Staff Communication",
        "PPT/504 Meeting",
        "Classroom Observation/Push-In",
        "Group/Workshop/Lunch Bunch",
      ],

      checkinLength: [
        { text: "Select One", value: null },
        "0-5 mins.",
        "5-10 mins.",
        "10-15 mins.",
        "15-20 mins.",
        "20-30 mins.",
        "30-45 mins.",
        "45-60 mins",
        "60+ mins.",
        "attempted",
      ],
      show: true,

      // text/keyword filter
      filter: "",

      modes: [
        //'multi',
        "single",
        //'range'
      ],
      //fields: ['selected', 'isActive', 'age', 'first_name', 'last_name'],
      caseload_fields: [
        //{key: 'otc', label: 'OTC', sortable: true, sortDirection: 'desc', sort_priority: 0},
        {
          key: "otc_student",
          hide: true,
          label: "OTC - Student",
          sortable: true,
          sortDirection: "desc",
          sort_priority: 0,
        },
        //{key: 'student_name', label: 'Student', sortable: true, sortDirection: 'desc', sort_priority: 0},
      ],
      otcMenuFields: [
        {
          key: "otc",
          hide: true,
          label: "OTC",
          sortable: true,
          sortDirection: "desc",
          sort_priority: 0,
        },
      ],
      selectMode: "single",
      selected: [],
      students: [],
    };
  },

  watch: {
    globalQuery() {
      // Force refreshing the data table.
      this.$root.$emit("bv::refresh::table", this.v);
    },
  },

  computed: {
    otcMenu() {
      let otcListMap = this.$store.getters.check_in_history.map((i) => ({
        otc: i.otc,
      }));
      let otcList = [];

      otcListMap.forEach((element) => {
        let i = element.otc;
        otcList.push(i);
      });

      let menuSet = new Set(otcList);
      let menuArray = Array.from(menuSet);
      //console.log(menuArray)

      let final = [];
      menuArray.forEach((element) => {
        let i = { otc: element };
        final.push(i);
        //console.log(i)
      });
      return final;
    },

    viewAll() {
      var data = this.$store.getters.view_all;

      let all_otc_checkins = [];

      data.forEach((element) => {
        //console.log(element)
        let i = element.check_in;
        //console.log(i)

        i.forEach((element) => {
          all_otc_checkins.push(element);
        });
        //console.log(all_otc_checkins)
      });

      console.log(all_otc_checkins);
      return all_otc_checkins;
    },

    caseload() {
      return this.$store.getters.caseload;
    },

    check_in_history() {
      return this.$store.getters.check_in_history;
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },

  mounted() {
    // Set the initial number of items
    //this.totalRows = this.v.length // not using this anymore
  },

  methods: {
    onSubmit(event) {
      event.preventDefault();
      //alert(JSON.stringify(this.form))
    },

    onRowSelected(items) {
      this.selected = items;
      //this.student_doc_id = items
      this.selected.forEach((element) => {
        //console.log(element.document_id)

        let doc_id = element.document_id;
        //console.log(doc_id)

        var x = this.checkins_source(doc_id);
        console.log(x);
        //var x = this.viewAllCheckins()
        this.v = x;
        console.log(this.v);

        this.students = items;

        // set totalRows value to enable pagination
        this.totalRows = x.length;
      });
    },

    onOtcRowSelected(items) {
      this.selected = items;

      console.log(this.selected);
      let data = this.viewAll;
      let otc = this.selected[0].otc;
      console.log(otc);

      let filtered = data.filter((x) => x.otc == otc.toLowerCase());
      console.log(filtered);

      let checkins = filtered.map((c) => ({
        student_name: c.student_name,
        check_in_id: c.check_in_id,
        check_in_type: c.check_in_type,
        check_in_length: c.check_in_length,
        youth_development: c.youth_development,
        is_impromptu: c.is_impromptu,
        student_id: c.student_id,
        timestamp: c.timestamp,
        display_date:
          new Date(c.timestamp).toLocaleDateString("en-US", {
            timeZone: "UTC",
          }) +
          " " +
          new Date(c.timestamp).toLocaleTimeString(),
        sort_timestamp: new Date(c.timestamp).toLocaleString("en-US", {
          timeZone: "UTC",
        }),
        check_in_date: c.check_in_date,
        notes: c.notes,
        //document_id: doc_id,
        tracker_key_ref: c.tracker_key_ref,
        district: c.district,
        otc: this.capitalizeFirstLetter(c.otc),
      }));
      //console.log(checkins)

      var sorted = checkins.sort(function (a, b) {
        var c = new Date(a.sort_timestamp).getTime();
        var d = new Date(b.sort_timestamp).getTime();
        return d - c;
      });

      //console.log(sorted)
      this.allCheckins = sorted;
      this.totalAllRows = sorted.length;

      return sorted;
    },

    onFilteredStudents(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    testFn() {
      console.log(typeof this.selected);
      alert(this.check_in_history);
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    checkins_source(doc_id) {
      //let student = doc_id;

      let data = this.$store.state.student_data.filter(
        (x) => x.document_id == doc_id
      )[0].check_ins;
      let checkins = data.map((c) => ({
        student_name: c.student_name,
        check_in_id: c.check_in_id,
        check_in_type: c.check_in_type,
        check_in_length: c.check_in_length,
        youth_development: c.youth_development,
        is_impromptu: c.is_impromptu,
        student_id: c.student_id,
        timestamp: c.timestamp,
        display_date:
          new Date(c.timestamp).toLocaleDateString("en-US", {
            timeZone: "UTC",
          }) +
          " " +
          new Date(c.timestamp).toLocaleTimeString(),
        sort_timestamp: new Date(c.timestamp).toLocaleString("en-US", {
          timeZone: "UTC",
        }),
        check_in_date: c.check_in_date,
        notes: c.notes,
        document_id: doc_id,
        tracker_key_ref: c.tracker_key_ref,
        district: c.district,
        otc: this.capitalizeFirstLetter(c.otc),
      }));

      //alert(typeof checkins)

      var sorted = checkins.sort(function (a, b) {
        var c = new Date(a.sort_timestamp).getTime();
        var d = new Date(b.sort_timestamp).getTime();
        return d - c;
      });

      return sorted;

      //return checkins
    },

    onEdit(item) {
      if (item.isEditing) item.isEditing = false;
      else this.$set(item, "isEditing", true);
      //alert(item)
    },

    info(item, index, button) {
      //this.infoModal.title = `Row index: ${index}`
      this.infoModal.title = item.student_name;
      //this.infoModal.content = JSON.stringify(item, null, 2)
      this.infoModal.content = item;
      this.copiedModalContent = JSON.parse(JSON.stringify(item));
      //alert(this.copiedModalContent.check_in_type)
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },

    openDeleteModal(item, index, button) {
      //this.deleteModal.title = `Row index: ${index}`
      this.deleteModal.title = item.student_name;
      this.deleteModal.content = item;
      this.$root.$emit("bv::show::modal", this.deleteModal.id, button);
    },

    hideUpdateModal() {
      this.$root.$emit("bv::hide::modal", this.infoModal.id);
    },

    hideDeleteModal() {
      this.$root.$emit("bv::hide::modal", this.deleteModal.id);
    },

    resetDeleteModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },

    deleteConfirmation(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      //this.infoModal.content = JSON.stringify(item, null, 2)
      this.infoModal.content = item;

      //alert(this.copiedModalContent.check_in_type)
      this.$root.$emit("bv::show::modal", this.deleteModal.id, button);
    },
    onFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = this.v.length;
      this.currentPage = 1;
    },

    onOtcFiltered() {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = this.allCheckins.length;
      this.currentPage = 1;
    },
  },
};
</script>


<style>
#search-box {
  margin-top: 100px;
  margin-bottom: 20px;
  align-content: initial;
}

table.text-center * {
  text-align: left;
}

.left-col {
  border-right: 1px dashed #333;
}

#profile-card {
  margin-top: -15px;
  margin-bottom: 15px;
}

.action {
  height: 29px !important;
  width: 55px !important;
  margin-top: 2px;
  padding-bottom: 2px !important;
}

#confirm-delete {
  margin-top: 0px;
}

.b-form-datepicker {
  margin-right: 4px;
}

.table {
  color: black !important;
}

label {
  text-align: left;
  font-weight: bold;
}

.col-form-label {
  text-align: left;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
</style>