<template>
  <b-container @contextmenu.prevent>
    <div v-if="$store.state.isBusy">
      <b-button variant="primary" disabled>
        <b-spinner small type="grow"></b-spinner>
        Loading...
      </b-button>
      <!-- <b-row class="p-2">
          <b-skeleton-img width="100%" height="50px" no-aspect>
          </b-skeleton-img>
        </b-row> -->
      <!-- <b-row class="p-2">
          <b-skeleton-table
            :rows="20"
            :columns="8"
            :table-props="{ bordered: true, striped: true }"
          ></b-skeleton-table>
        </b-row> -->
    </div>
    <b-row>
      <b-col sm="2 offset-0" class="left-col" v-if="!$store.state.isBusy">
        <div id="selection-text" align="left">
          <p>Select an On-Track Coach.</p>
        </div>

        <div>
          <div>
            <b-table
              sticky-header="400px"
              :items="this.otcMenu"
              :fields="otcMenuFields"
              :filter="side_filter"
              :select-mode="selectMode"
              sort-by="otc"
              responsive="sm"
              ref="selectableTable"
              selectable
              @row-selected="onOtcRowSelected"
              @filtered="onFilteredStudents"
            >

            </b-table>
   
          </div>
        </div>
      </b-col>

      <b-col sm="9">
        <div id="everything" v-if="!$store.state.isBusy">

          <b-row id="controls" align-v="center">
            <b-col id="pagination">
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="rows"
                :per-page="pagination.perPage"
                class="m-0"
              ></b-pagination>
            </b-col>

            <b-col>
              <b-form-select
                id="per-page-select"
                v-model="pagination.perPage"
                :options="pagination.pageOptions"
              ></b-form-select>
            </b-col>

            <b-col id="column-control-2">
              <b-form-select
                v-model="column_group_selected"
                :options="column_group_options"
                @change="visibleFieldsControl(column_group_selected)"
              >
              </b-form-select>
            </b-col>

          </b-row>

          <!-- BEGIN TABLE  -->

          <!-- contextmenu.prevent in order to make right clicking work to open filter menu -->
          <b-row id="table" @contextmenu.prevent>
            <b-table
              sticky-header="100%"
              head-variant="light"
              responsive
              hover
              small
              :fields="tableFields"
              :items="this.selectedOTC"
              :per-page="pagination.perPage"
              :current-page="pagination.currentPage"
              :filter="popFilter.filter_table"
              class="table-condensed"
              borderless
              style="min-width: 0"
              striped
              sort-by="otc"
            >

              <template #cell(show_grades)="student_row">
                <div
                  :id="
                    student_row.field.key.concat('_').concat(student_row.index)
                  "
                  @click="setGradesStudent(student_row.item)"
                >
                  <b-button>Grades</b-button>
                </div>
              </template>
            </b-table>
          </b-row>

          <!-- BEGIN POPOVER  (for filtering)-->

          <b-popover
            :target="this.popFilter.popTarget"
            :show="this.popFilter.showPop"
            placement="right"
            @contextmenu.prevent
          >

            <div id="popover_inputs" @contextmenu.prevent>
              <b-form-group
                label-for="filter-drop-down"
                description="Hold control to select more than one option"
                class="mb-1"
              >
                <b-form-select
                  id="filter-drop-down"
                  multiple
                  v-model="popFilter.selectedFilterOptions"
                  :options="popFilter.filterOptions"
                >
                </b-form-select>
              </b-form-group>

              <b-button
                @click="onFilterOk"
                size="sm"
                class="m-1"
                variant="primary"
                >Filter</b-button
              >
              <b-button
                @click="onFilterClear"
                size="sm"
                class="m-1"
                variant="danger"
                >Clear Filter</b-button
              >
              <b-button
                @click="onFilterClose"
                size="sm"
                class="m-1"
                variant="secondary"
                >Cancel</b-button
              >
            </div>
          </b-popover>

          <!-- END POPOVER (for filtering)-->

          <!-- START MODAL -->
          <b-modal
            id="grades-modal"
            :title="this.studentGradesProperties.targetStudentName"
          >
            <gradesTable
              :student_doc_id="this.studentGradesProperties.targetStudent"
            >
            </gradesTable>
          </b-modal>
          <!-- END MODAL -->
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>


<script>
import * as d3 from "d3";
import * as config from "../config/config.js";
//import barGraph from '../components/barGraph.vue'
import * as fb from "../firebase";
// import db from '../firebase.js'
//import firebase from 'firebase/app';
import gradesTable from "../components/gradesTable.vue";
//import JsonCSV from 'vue-json-csv'

export default {
  components: {
    //formModal
    //barGraph,
    gradesTable,
    //, 'download-csv': JsonCSV
  },

  data() {
    return {
      selectedOTC: [],
      tableFields: [],
      fieldGroups: [],
      buttonData: [],
      isBusy: false,
      currentUser: fb.auth.currentUser,
      firestore_data: [],

      //used for interactive fields that can be edited by users
      edit_row: "",
      edit_field: "",
      doc_id: "",
      ///////////////////////////////////////////////////////
      //used for changing which fields are in view
      column_group_selected: "",
      otcMenuFields: config.otcMenuFields,
      column_group_options: config.column_group_options,
      // , pickedFields: []
      // , selectedStep: ''
      selectedStepGraph: [],
      selectedStepGraphLabels: {},
      //////////////////////////////////////////////////////
      //table pagination
      pagination: config.trackerPagination,
      /////////////////////////////////////////////////////////

      popFilter: {
        filterTitle: "placeholder title",
        filterField: "",
        filterOptions: [
          { text: "- Choose 1 -", value: "" },
          "Red",
          "Green",
          "Blue",
        ],
        selectedFilterOptions: [],
        clearButtonDisabled: true,
        clearButtonVariant: "secondary",
        popTarget: "",
        showPop: false,
        filter_table: "",
      },
      ///////////////////////////////////////////////////////////
      studentGradesProperties: {
        targetStudent: "",
        targetStudentName: "",
      },
      /////////////////////////////////////////////////
    };
  },
  async created() {

    await this.load_data();
    // await this.setCaseloadSummary()
    console.log("tracker.vue created");
    //    this.load_data()
  },
  /*async mounted(){
        console.log("tracker.vue mounted")
        // this.load_data()
        
    },
    beforeMount(){
        // this.load_data()
    },*/
  computed: {
    otcMenu() {
      let otcListMap = this.$store.getters.check_in_history.map((i) => ({
        otc: i.otc,
      }));
      let otcList = [];

      otcListMap.forEach((element) => {
        let i = element.otc;
        otcList.push(i);
      });

      let menuSet = new Set(otcList);
      let menuArray = Array.from(menuSet);
      //console.log(menuArray)

      let final = [];
      menuArray.forEach((element) => {
        let i = { otc: element };
        final.push(i);
        //console.log(i)
      });
      return final;
    },

    caseloadSummary() {
      var data = this.$store.getters.caseload_summary;
 
      console.log(data);
      alert(data.length);

      var otc_list = new Set(data.map((d) => d.otc));
      //console.log(otcs)

      var status_list = new Set(data.map((d) => d.on_track_status));
      console.log(status_list);

      var otc_summary_array = [];

      otc_list.forEach((element) => {
        //console.log(element)
        let i = element;

        status_list.forEach((element) => {
          //console.log(element)

          let j = element;
          let len = data.filter(
            (d) => d.on_track_status == j && d.otc == i
          ).length;
          let otc_status_count = { otc: i, on_track_status: j, count: len };
          otc_summary_array.push(otc_status_count);
        });
      });
      console.log(otc_summary_array);
      return otc_summary_array;
    },

    studentDataContainer() {
      return this.$store.state.student_data;
    },

    liveDeepStudents() {
      var x = this.$store.getters.preppedTableData;

      // var x = this.deepStudents

      var i;
      let f = this.filteredFields;
      if (f.length > 0) {
        for (i in f) {
          let filter_set = this.tableFields.filter((x) => x.key == f[i])[0]
            .selectedFilterOptions;

          x = x.filter((k) => filter_set.includes(k[f[i]]));
        }
      }
      return x;
    },

    //this is the list of field groups that will show up in the visible column drawer
    customizableFieldGroups() {
      var x = this.fieldGroups.filter((k) => k.makecard == "true");

      return x;
    },

    //tells the pagination controls the maximum number of rows in the table
    rows() {
      return this.liveDeepStudents.length;
    },

    ///////////////////////////////////////////
    //used for graphs
    aggStepComplete() {
      // aggregate the step_completed field for the selected step

      const stepName = this.selectedStep;
      const x = d3.rollup(
        this.liveDeepStudents,
        (v) => v.length,
        (d) => d[stepName]
      );

      const y = Array.from(x, ([a, count]) => ({ a, count }));

      let z = y.map(({ a, count }) => ({ [stepName]: a, students: count }));
      z = z.sort((a, b) => {
        let fa = a[stepName],
          fb = b[stepName];
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });
      return z;
    },
    ////////////////////////////////////////////

    //////////////////////////////////////////////
    //used for filtering
    filteredFields() {
      let x = this.tableFields.filter((k) => k.filtered);

      x = x.map((k) => k.key);

      return x;
    },
    /////////////////////////////////////////////
  },
  methods: {
    say: function (msg) {
      console.log(msg);
    },

    async load_data() {
      //    this.isBusy = true;
      console.log("begin load data");
      try {
        this.tableFields = await this.$store.state.fields;
      } catch {
        console.log("fields load failed");
      }

      try {
        this.fieldGroups = await this.$store.state.field_groups;
      } catch {
        console.log("field_groups load failed");
      }

      try {
        this.buttonData = await this.$store.state.button_data;
      } catch {
        console.log("button_data failed");
      }

      try {
        this.visibleFieldsControl("Student Profile Stats");
        this.column_group_selected = "Student Profile Stats";

        //this.deepStudents = this.$store.state.student_data;
      } catch {
        console.log("visibleFieldsControl failed");
      }
    },

    /////////////////////////////////////////////////////
    //used for setting up for loops
    toggle_field_list: function (ftype) {
      return this.tableFields.filter((x) => x.edit_type == ftype);
    },

    //////////////////////////////////////////////////////

    /////////////////////////////////////////////////////
    // used for controlling which fields are on screen
    visibleFieldsControl: function (option) {
      console.log("Menu triggered for:" + option);
      let f = {};
      f = this.buttonData.filter((k) => k.label == option)[0];
      this.pickedFields = f.fields;
      this.selectedStep = f.graph_config.aggregate_on;
      this.selectedStepGraphLabels = f.graph_config;
      this.tableFields.forEach((x) =>
        this.pickedFields.includes(x.key)
          ? ((x.tdClass = ""), (x.thClass = ""))
          : ((x.tdClass = "d-none"), (x.thClass = "d-none"))
      );

      console.log(f);
    },

    checkColumn: function (arg1) {
      let n = "";
      n = this.tableFields.filter((x) => x.key == arg1[0]);
      n[0].tdClass = n[0].thClass;
    },

    field_by_type: function (ftype) {
      return this.tableFields.filter((x) => x.field_type == ftype);
    },

    /////////////////////////////////////////

    /////////////////////////////////////////

    onFilterOk() {
      if (this.popFilter.selectedFilterOptions.length > 0) {
        let chosenField = this.popFilter.filterField;
        //this.say(this.popFilter.selectedFilterOptions)

        this.tableFields.filter(
          (k) => k.key == chosenField
        )[0].selectedFilterOptions = this.popFilter.selectedFilterOptions;
        this.tableFields.filter((k) => k.key == chosenField)[0].filtered = true;
      }
      //this.say(this.fields.filter(k=>k.key==chosenField)[0].selectedFilterOptions)
      this.popFilter.showPop = !this.popFilter.showPop;
    },

    onFilterClear() {
      let chosenField = this.popFilter.filterField;
      this.tableFields.filter(
        (k) => k.key == chosenField
      )[0].selectedFilterOptions = [];
      this.tableFields.filter((k) => k.key == chosenField)[0].filtered = false;
      this.popFilter.showPop = !this.popFilter.showPop;
    },

    onFilterClose() {
      this.popFilter.showPop = !this.popFilter.showPop;
    },

    clearAllFilters() {
      this.tableFields
        .filter((k) => k.filtered)
        .forEach((f) => (f.filtered = false));
    },

    ////////////////////////////////////
    //Grades table methods

    setGradesStudent(payload) {
      this.studentGradesProperties.targetStudent = payload.doc_id;
      this.studentGradesProperties.targetStudentName = payload.student_name;

      this.$root.$emit("bv::show::modal", "grades-modal");
    },

    onOtcRowSelected(items) {
      this.selected = items;

      this.selectedOTC = [].pop(0);

      console.log(this.selected);
      let data = this.liveDeepStudents;
      let otc = this.selected[0].otc;
      console.log(data);

      let filtered = data.filter(
        (x) => x.otc_title_case.toLowerCase() == otc.toLowerCase()
      );
      console.log(filtered);

      this.selectedOTC = filtered;

      return filtered;
    },

    setCaseloadSummary() {
      let students = this.$store.state.student_data;
      let data = students.map((c) => ({
        otc: c.otc,
        on_track_status: c.on_track_status,
      }));
      //var data = this.caseloadSummary
      console.log(data);
      alert(data.length);

      var otc_list = new Set(data.map((d) => d.otc));
      //console.log(otcs)

      var status_list = new Set(data.map((d) => d.on_track_status));
      console.log(status_list);

      var otc_summary_array = [];

      otc_list.forEach((element) => {
        //console.log(element)
        let i = element;

        status_list.forEach((element) => {
          //console.log(element)

          let j = element;
          let len = data.filter(
            (d) => d.on_track_status == j && d.otc == i
          ).length;
          let otc_status_count = { otc: i, on_track_status: j, count: len };
          otc_summary_array.push(otc_status_count);
        });

        console.log(otc_summary_array);
        return otc_summary_array;
      });
    },
  },
};
</script>

<style scoped>

.left-col {
    margin-right: 20px;
    margin-top: 60px;
}

#controls {
  margin-top: 60px;
  margin-bottom: 10px;
}

.editable-column-true {
  font-weight: bold;
}

.editable-column-false {
  font-weight: bold;
}

.toggle-header {
  text-decoration: underline overline;
}

.table-condensed {
  font-size: 13px;
}

.control-row {
  /* font-size: 13px; */
  margin: 0;
}

/* .scrollable-dropdown {
    max-height: 300px;
    overflow-y: auto;
  } */

.scrollable-menu {
  height: auto;
  max-height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.not-too-wide {
  max-width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tooltip {
  text-transform: capitalize;
}

.editable-column-true {
  font-weight: bold;
}

.toggle-header {
  font-weight: bold;
}
</style>