module.exports={

otcMenuFields: [
    {key: 'otc', hide: true, label: 'OTC', sortable: true, sortDirection: 'desc', sort_priority: 0},
    ], 

column_group_options: [
    {value: null, text: 'Please select an option', disabled: true}
    , {value: 'Student Profile Stats', text: 'Academic Performance'}
    //, {value: 'Student Profile Stats', text: 'Check-in Milestones'}
    //, {value: 'Student Profile Stats', text: 'Alerts'}
    , {
        label: 'Check-in Milestone Trackers',
        options: [
            {value: 'September Milestones', text: 'September'}
            , {value: 'October Milestones', text: 'October'}
            , {value: 'November Milestones', text: 'November'}
            , {value: 'December Milestones', text: 'December'}
            , {value: 'January Milestones', text: 'January'}
            , {value: 'February Milestones', text: 'February'}
            , {value: 'March Milestones', text: 'March'}
            , {value: 'April Milestones', text: 'April'}
            , {value: 'May Milestones', text: 'May'}
            , {value: 'June Milestones', text: 'June'}
        ]
    }
],

trackerPagination:
                {
                    perPage: 15,
                    currentPage: 1,
                    pageOptions: [10, 15, 30, { value: 99900, text: "Show All" }],
                }
}