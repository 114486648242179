// This import loads the firebase namespace.
import firebase from 'firebase/app';

// These imports load individual services into the firebase namespace.
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';



  // Your web app's Firebase configuration
  const firebaseConfig = {
    apiKey: "AIzaSyDIf9vjKTjPsRfpI0ZyJKeJoCtVOjYUfXU",
    authDomain: "rise-otc-api.firebaseapp.com",
    databaseURL: "https://rise-otc-api.firebaseio.com",
    projectId: "rise-otc-api",
    storageBucket: "rise-otc-api.appspot.com",
    messagingSenderId: "907673642400",
    appId: "1:907673642400:web:1eb2f2ed6baebf2bf030c5"
  };


  // Initialize Firebase
  const firebaseApp=firebase.initializeApp(firebaseConfig);

  // utils
const db = firebaseApp.firestore()
const auth = firebase.auth()

// collection references
//const studentsCollection = db.collection('otc_collection_2').doc('merryville').collection('dolbey')
// const studentsCollection = db.collection('ag_test_collection_8')
const oneStudent = db.collection('ag_test_collection_8').doc('1kAmelpEig5lDHPusq5M')
//const otcStudents = db.collection('otc_caseload_collection').doc('merryville')
//const otcStudents = db.collection('all_schools').doc('merryville')

//const otcDocument = db.collection('all_schools').document(i['district'].lower()).collection('all_otcs').document(i['otc'])
//const otcStudents = db.collection('all_schools2')
const otcStudents = db.collection('prod_all_schools')
//.document(i['district'].lower()).collection('all_otcs').document(i['otc']).collection('caseload')

const schoolsCollection = db.collection('prod_all_schools')
const demo_schoolsCollection = db.collection('demo_all_schools')

const multiDistrictStudents = db.collectionGroup('prod_caseload')

//users collection
const userDb = db.collection('users')

// export utils/refs
export {
  db,
  auth,
  userDb,
  schoolsCollection,
  demo_schoolsCollection,
  multiDistrictStudents,
  //studentsCollection,
  oneStudent,
  otcStudents

}